<template>
  <section class="listar-segurado pr-4">
    <div class="m-1 mr-4 px-0">
      <breadcrumb title="Segurados" actualPage="Lista de segurados" />
    </div>

    <div
      class="grid grid-cols-3 mt-8 mb-10 gap-3 w-full md:w-4/5 lg:w-3/5 lp:w-1/2"
    >
      <filtro ref="filtro" :isFilter="filtro">
        <div class="pt-2 pb-6 px-4">
          <div class="flex flex-col mt-4 md:mt-8 md:flex-row">
            <vs-input
              size="large"
              label-placeholder="Nome do Segurado"
              class="w-full mt-4 md:w-3/5 md:pr-3 md:m-0"
              v-model="filter.insuredName"
              @keyup.enter="listarSegurados(true)"
            />
            <vs-input
              size="large"
              label-placeholder="CNPJ Segurado"
              class="w-full mt-8 md:w-2/5 md:m-0"
              v-model="filter.insuredDocument"
              @keyup.enter="listarSegurados(true)"
            />
          </div>

          <div class="flex flex-row-reverse mt-4">
            <vs-button
              @click="listarSegurados(true)"
              color="primary"
              type="filled"
              class="ml-4"
              >Aplicar filtro</vs-button
            >
            <vs-button
              color="primary"
              type="filled"
              icon="clear"
              @click="limparFiltros()"
              >Limpar filtro</vs-button
            >
          </div>
        </div>
      </filtro>
    </div>

    <vs-popup
      title="Você ainda tem documentos pendentes e/ou aguardando análise pela seguradora  "
      :active.sync="modalDocuments"
      class="popup-width-auto"
      style="word-wrap: break-word;"
    >
      <vs-button
        router-link
        to="/corretor"
        class="w-full sm:w-7/12 sm:float-right"
        @click="modalDocuments = false"
      >
        Ir para dados da corretora
      </vs-button>
    </vs-popup>

    <div class="table-content pt-2">
      <template v-if="segurados.length > 0">
        <vs-table
          class="table-list"
          noDataText="Nenhum segurado encontrado"
          :data="segurados || []"
          :hoverFlat="true"
        >
          <template slot="thead">
            <vs-th class="text-center">Ações</vs-th>
            <vs-th class="text-left">CPF/CNPJ</vs-th>
            <vs-th class="text-left">Nome</vs-th>
            <vs-th>Ramo</vs-th>
            <vs-th>Bloqueado</vs-th>
            <vs-th>Data bloqueio</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr
              :data="tr"
              :state="data[indextr].InsuredIsBlocked === true ? 'danger' : null"
              :key="indextr"
              v-for="(tr, indextr) in data"
            >
              <vs-td :data="data[indextr].InsuredUniqueId" class="text-center">
                <vs-dropdown
                  vs-custom-content
                  vs-trigger-click
                  class="cursor-pointer"
                >
                  <vs-button
                    color="dark"
                    type="flat"
                    title="Ações"
                    :disabled="
                      profileHelpers.checkPermission([
                        profileHelpers.menuFuncionalitiesEnum
                          .GERENCIAR_SEGURADOS,
                        profileHelpers.menuFuncionalitiesEnum
                          .VISUALIZAR_SEGURADO
                      ])
                    "
                  >
                    <i class="onpoint-dots-three-vertical icon-dots-three-vertical" role="button"></i>
                  </vs-button>

                  <vs-dropdown-menu style="width: 200px">
                    <vs-dropdown-item
                      v-if="
                        profileHelpers.checkPermission([
                          profileHelpers.menuFuncionalitiesEnum
                            .VISUALIZAR_SEGURADO
                        ])
                      "
                      @click="goToInsuredDetail(data[indextr].InsuredUniqueId)"
                    >
                      <p class="text-base px-6 py-2">
                        <span>Visualizar</span>
                      </p>
                    </vs-dropdown-item>

                    <vs-dropdown-item
                      v-if="
                        profileHelpers.checkPermission([
                          profileHelpers.menuFuncionalitiesEnum
                            .GERENCIAR_SEGURADOS
                        ])
                      "
                      @click="
                        openModalBlock(
                          data[indextr].InsuredId,
                          !!data[indextr].InsuredIsBlocked
                        )
                      "
                    >
                      <p class="text-base px-6 py-2">
                        <span>{{
                          !data[indextr].InsuredIsBlocked
                            ? "Bloquear"
                            : "Desbloquear"
                        }}</span>
                      </p>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-td>
              <vs-td :data="data[indextr].InsuredDocument" class="text-left">
                <span class="font-semibold">
                  {{ data[indextr].InsuredDocument | maskCpfCnpj }}
                </span>
              </vs-td>

              <vs-td :data="data[indextr].InsuredName">
                <span class="font-semibold">
                  {{ data[indextr].InsuredName }}
                </span>
              </vs-td>

              <vs-td :data="data[indextr].InsuredBranch">
                <span class="font-semibold">
                  {{ data[indextr].IsPrivate ? "Privado" : "Publico" }}
                </span>
              </vs-td>

              <vs-td>
                <span class="font-semibold">
                  {{ data[indextr].InsuredIsBlocked ? "Sim" : "Não" }}
                </span>
              </vs-td>

              <vs-td>
                <span
                  class="font-semibold"
                  v-if="data[indextr].InsuredIsBlocked"
                >
                  {{ data[indextr].InsuredBlockDate | moment("DD/MM/YYYY") }}
                </span>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>

        <vs-pagination
          v-if="numberOfPages > 1"
          class="mt-5"
          :total="numberOfPages"
          v-model="currentPage"
        ></vs-pagination> 
      </template>

      <template v-else>
        <div class="flex flex-col w-full md:w-1/2 mx-auto text-center mt-32">
          <p><span class="material-icons text-6xl">description</span></p>
          <p class="text-center">
            Você ainda não possui nenhum segurado cadastrado.
          </p>
        </div>
      </template>
    </div>

    <vs-popup
      class="validacao-data-vigente-modal"
      :title="modalTitle"
      :active.sync="openModalBlockInsured"
    >
      <div class="vw-row mt-3">
        <div class="vx-col pt-5 w-full mt-2" v-if="!isBlocked">
          <label class="font-semibold">Justificativa</label>
          <vs-textarea v-model="justifyBlock" />
        </div>
        <div class="vx-col w-full text-right">
          <vs-button @click="closeModalBlock()" class="button-default mr-5">
            Cancelar
          </vs-button>
          <vs-button @click="blockInsured()" class="button-primary">
            Confirmar
          </vs-button>
        </div>
      </div>
    </vs-popup>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import * as Utils from "@/utils/utils";
import { mask, TheMask } from "vue-the-mask";
import Breadcrumb from "@/layouts/components/breadcrumb/Breadcrumb.vue";
import Filtro from "@/components/Filtro/Filtro";

import PerfilHelpers from "@/helpers/PerfilHelpers";


export default {
  components: { TheMask, Breadcrumb, Filtro },
  directives: { mask },
  data() {
    return {
      filtro: false,
      modalDocuments: false,
      currentPage: 1,
      rowsPerPage: 10,
      filter: {
        insuredDocument: "",
        insuredName: ""
      },
      openModalBlockInsured: false,
      insuredUniqueId: null,
      modalTitle: "",
      justifyBlock: "",
      isBlocked: false,

      profileHelpers: PerfilHelpers
    };
  },
  async mounted() {
    await this.$onpoint.loading(async () => {
      await this.listarSegurados();
      await this.obterModuloPermissoes();
    });
  },
  created() {
    this.$appInsights.trackPageView({
      name: "segurados-listar",
      url: window.location.href
    });
  },
  computed: {
    ...mapGetters("cadastro-corretora", ["bDocumentacaoPendente"]),
    ...mapGetters("segurado-module", ["searchSeguradosResult", "segurados"]),
    numberOfPages: {
      get() {
        return (this.searchSeguradosResult || {}).NumberOfPages || 0;
      }
    }
  },
  watch: {
    currentPage(newVal, oldVal) {
      this.listarSegurados();
    }
  },
  methods: {
    ...mapActions("segurado-module", [
      "searchSegurados",
      "blockUnblockInsured"
    ]),
    ...mapActions("cadastro-corretora", ["getCorretora"]),

    goToInsuredDetail(InsuredUniqueId) {
      this.$router.push({
        name: "detalhe-segurado",
        params: {
          seguradoUniqueId: InsuredUniqueId
        }
      });
    },

    openModalBlock(insuredId, isBlocked) {
      this.modalTitle = isBlocked
        ? "Deseja realmente desbloquear o segurado?"
        : "Deseja realmente bloquear o segurado?";
      this.insuredId = insuredId;
      this.openModalBlockInsured = true;
      this.justifyBlock = "";
      this.isBlocked = isBlocked;
    },

    closeModalBlock() {
      this.InsuredId = null;
      this.openModalBlockInsured = false;
    },

    async blockInsured() {
      if (!this.justifyBlock && !this.isBlocked) {
        this.$onpoint.errorModal("Justificativa obrigatória");
        return;
      }
      await this.$onpoint.loading(async() => {
        return await this.blockUnblockInsured({
          id: this.insuredId,
          justify: this.justifyBlock,
          isBlocked: !this.isBlocked
        })
          .then(resp => {
            const action = this.isBlocked ? "desbloqueado" : "bloqueado";
            this.listarSegurados();
            this.$vs.notify({
              title: "Sucesso",
              text: `Segurado ${action} com sucesso!`,
              color: "success"
            });
            this.openModalBlockInsured = false;
          })
          .catch(err => {
            this.$onpoint.errorModal(err.response.data.Errors);
          });
      });
    },

    async listarSegurados(resetPage) {
      this.filtro = !this.filtro;
      if (resetPage) this.currentPage = 1;

      const params = {
        filter: {
          ...this.filter,
          insuredDocument: Utils.removeSpecialChars(this.filter.insuredDocument)
        },
        currentPage: this.currentPage,
        rowsPerPage: this.rowsPerPage
      };

      await this.$onpoint.loading(async () => {
        return await this.searchSegurados(params).catch(err => {
          this.$onpoint.errorModal(err.response.Message);
        });
      });
    },

    limparFiltros() {
      this.filter = {
        insuredName: "",
        insuredDocument: ""
      };
      this.currentPage = 1;
      this.listarSegurados();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/mixins";

.con-vs-popup {
  z-index: 52000;
}

.table-list {
  margin-top: -32px;

  ::v-deep .vs-table--thead {
    tr {
      background: transparent !important;
    }
  }

  ::v-deep .vs-table--td {
    padding: 31px 15px !important;
    margin-bottom: 1em;
  }

  ::v-deep .vs-table--search {
    max-width: initial;
    margin-bottom: 20px;
    i {
      font-size: 2em;
      margin-top: 30px;

      @include md {
        margin-top: -24px;
      }
    }

    input {
      padding-left: 40px !important;
      font-size: 1.4em !important;
      width: 300px;
      border: 0 !important;
      height: 47px;
      margin-top: 30px;

      @include md {
        margin-top: -24px;
      }
    }
  }

  ::v-deep .tr-values {
    margin-bottom: 5px;
    border-left: solid 8px rgba(var(--vs-warning), 1);
    border-bottom: solid 10px #f8f8f8;
  }
}

.icon-dots-three-vertical::before{
  position: relative;
  top: 2px;
  font-size: 24px;
  color: #afafaf;
}
</style>
